<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="row">
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <select class="form-select"
                                        @change="filteredValue"
                                        aria-label="Company">
                                    <option selected value=""> {{ translations?.all }}</option>
                                    <option v-for="(item, index) in dataList.companies"
                                            :key="`company-${index}`"
                                            :value="item.id">
                                        {{ item.company_name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <data-table 
                        :translations="translations"
                        :options="dataList.options"
                        :data-set="data.dataList"
                        :page-size="data.pageSize"
                        @pagination="pagination"
                        @limit-change="limitChange"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DataTable from "@/common/components/datatable";
import {reactive, onMounted, computed} from "vue";
import HelperFunction from "@/common/helpers";
import {useStore} from "vuex";

export default {
    name: "UserAccess",
    components: {DataTable},
    setup() {
        const store = useStore();
        const {
            data,
            getAllData,
            selectedCompany
        } = HelperFunction();

        const translations = computed(() => {
            return store.state.translations;  
        });

        let dataList = reactive({
            params: {
                page: null,
                limit: null,
                sort: 'desc',
                company_id: null,
                search: ''
            },
            companies: [],
            options: {
                name: translations.value?.user_access || 'User Access',
                columns: [
                    {
                        title: translations.value?.company || 'Company',
                        type: 'text',
                        key: 'company'
                    },
                    {
                        title: translations.value?.user || 'User',
                        type: 'custom',
                        key: 'user',
                        modifier: (obj, row) => {
                            return obj.name
                        }
                    },
                    {
                        title: translations.value?.login || 'Login',
                        type: 'text',
                        key: 'logged_in'
                    },
                    {
                        title: translations.value?.logout || 'Logout',
                        type: 'text',
                        key: 'logged_out'
                    },
                    {
                        title: translations.value?.device || 'Device',
                        type: 'text',
                        key: 'device'
                    },
                    {
                        title: translations.value?.browser || 'Browser',
                        type: 'text',
                        key: 'browser'
                    },
                    {
                        title: translations.value?.platform || 'Platform',
                        type: 'text',
                        key: 'platform'
                    },
                    {
                        title: translations.value?.ip || 'IP',
                        type: 'text',
                        key: 'visitor'
                    },
                ],
                showAction: false,
                actions: []
            }
        });

        onMounted(() => {
            getData();
            getCompanies()
        })

        const getData = () => {
            data.setURL = vueConfig.Administrator.UserAccess;
            dataList.params.company_id = selectedCompany.value.id ? selectedCompany.value.id : null;
            getAllData(data.setURL, dataList.params);
        }

        const getCompanies = () => {
            dataList.params.company_id = selectedCompany.value.id ? selectedCompany.value.id : null;
            store.dispatch('getCompanies', dataList.params)
                .then(res => {
                    dataList.companies = res.data;
                })
        }

        const pagination = (page) => {
            dataList.params.page = page;
            getData();
        }
        const limitChange = (limit) => {
            dataList.params.limit = limit;
            delete dataList.params.page
            getData();
        }

        const filteredValue = (event) => {
            let value = event.target.value;
            dataList.params.company_id = value ? value : null;
            getData();
        }

        return {
            data,
            dataList,
            limitChange,
            pagination,
            filteredValue,
            translations
        }
    }
}
</script>
